<template>
  <div>
    <BaseElFormItem
      v-if="useBindClassTickets"
      label="綁定堂票"
      prop="classTickets"
    >
      <BaseElSelect
        v-model="syncClassTickets"
        popper-class="select-popper"
        testName="formData_classTickets_bind"
        placeholder="選擇堂票"
        multiple
        collapse-tags
        @visible-change="showSelector = true"
        @change="onClassTicketsChange"
      >
        <BaseElSelectOption
          v-for="classTicket in displayClassTicketOptions"
          :key="classTicket.value"
          :label="classTicket.label"
          :value="classTicket.value"
        />
      </BaseElSelect>
    </BaseElFormItem>

    <BaseElFormItem v-if="syncClassTickets.length" label="此服務項目僅供堂票預約">
      <BaseElSwitch
        v-model="syncRequiredClassTicket"
        testName="formData_requiredClassTicket"
        inactive-text="否"
        active-text="是"
      />
    </BaseElFormItem>

    <ClassTicketAdvanceSelect
      v-if="showSelector"
      :data="classTicketList"
      :selectedData="syncClassTickets"
      :disabledExp="true"
      multiple
      @confirm="onSelected"
      @close="showSelector = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useVModel } from '@vueuse/core'
import ClassTicketAdvanceSelect from '@/components/Select/ClassTicketAdvanceSelect.vue'
import { map } from 'lodash'

export default defineComponent({
  name: 'BindClassTicketBlock',
  components: { ClassTicketAdvanceSelect },
  props: {
    classTickets: { type: Array, default: () => ([]) },
    requiredClassTicket: Boolean,
    useBindClassTickets: Boolean,
    classTicketList: { type: Array, default: () => ([]) },
  },
  emits: ['update:value'],
  setup (props, { emit }) {
    const syncClassTickets = useVModel(props, 'classTickets', emit)
    const syncRequiredClassTicket = useVModel(props, 'requiredClassTicket', emit)
    const showSelector = ref(false)

    const onSelected = (classTicketIds) => {
      if (!classTicketIds.length) {
        syncRequiredClassTicket.value = false
        return
      }
      syncClassTickets.value = classTicketIds
    }

    const onClassTicketsChange = (tickets) => {
      if (!tickets.length) {
        syncRequiredClassTicket.value = false
      }
    }

    const displayClassTicketOptions = computed(() => {
      return map(props.classTicketList, i => ({
        label: i.name,
        value: i.id,
      }))
    })

    return { syncClassTickets, showSelector, displayClassTicketOptions, onSelected, syncRequiredClassTicket, onClassTicketsChange }
  },
})

</script>

<style lang="postcss" scoped>

</style>
